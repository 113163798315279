.headline_3xl_500 {
  font-weight: 500;
  line-height: 150%;
  font-size: 2.5rem;
  letter-spacing: 0.25px;
}

.headline_2xl_500 {
  font-weight: 500;
  line-height: 150%;
  font-size: 2rem;
  letter-spacing: 0.25px;
}

.headline_xl_500 {
  font-weight: 500;
  line-height: 150%;
  font-size: 1.75rem;
  letter-spacing: 0.25px;
}

.heading_l_500 {
  font-weight: 500;
  line-height: 150%;
  font-size: 1.5rem;
  letter-spacing: 0.25px;
}
.heading_m_500 {
  font-weight: 500;
  line-height: 150%;
  font-size: 1.25rem;
  letter-spacing: 0.25px;
}
.body_l_500 {
  font-weight: 500;
  line-height: 150%;
  font-size: 1.125rem;
  letter-spacing: 0.25px;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
}
.body_m_500 {
  font-weight: 500;
  line-height: 175%;
  font-size: 1rem;
  letter-spacing: 0.25px;
}
.body_m_400 {
  font-weight: 400;
  line-height: 175%;
  font-size: 1rem;
  letter-spacing: 0.05px;
}

.body_s_500 {
  font-weight: 500;
  line-height: 175%;
  font-size: 0.875rem;
  letter-spacing: 0.05px;
}

.body_s_400 {
  font-weight: 400;
  line-height: 175%;
  font-size: 0.875rem;
  letter-spacing: 0.05px;
}

.label_s_500 {
  font-weight: 500;
  line-height: 175%;
  font-size: 0.75rem;
  letter-spacing: 0.05px;
}

.label_s_400 {
  font-weight: 400;
  line-height: 175%;
  font-size: 0.75rem;
  letter-spacing: 0.05px;
}

@media (min-width: 768px) {
  .body_l_500 {
    font-size: 1rem !important;
  }
  .body_m_500,
  .body_m_400 {
    font-size: 0.875rem !important;
  }
}
