@font-face {
  font-family: 'wu-icon';
  src: url('https://public.wordup.com.tw/WU_icon_font/WU_icon.ttf?v=2025-03-19') format('truetype');
}

.wu-icon {
  font-style: normal;
  font-family: 'wu-icon' !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
